<template>
  <v-autocomplete
    v-model="sources"
    v-bind="$attrs"
    :items="this.$sources"
    :item-disabled="(item) => {
      return exclude.includes(item.value)
    }"
    menu-props="offsetY, closeOnClick"
    :multiple="multiple"
    class="mt-4"
    @input="$emit('input', sources)"
  >
    <template #item="{ item }">
      <v-list-item-icon>
        <source-icon
          :key="item.text"
          :source="item.text"
          :size="20"
        />
      </v-list-item-icon>
      <v-list-item-title v-text="item.text" />
    </template>
    <template
      v-if="multiple"
      #selection="{ item }"
    >
      <source-chip
        :key="item.text"
        :source="item.value"
        :name="item.text"
        class="mr-0 mb-1"
      />
    </template>
  </v-autocomplete>
</template>
<script>
  export default {
    components: {
      SourceIcon: () => import('@/components/source/SourceIcon.vue'),
      SourceChip: () => import('@/components/source/SourceChip.vue'),
    },
    props: {
      value: {
        type: [Array, String],
        default: () => [],
      },
      exclude: {
        type: Array,
        default: () => [],
      },
      multiple: {
        type: Boolean,
        default: true,
      },
    },
    data () {
      return {
        loading: false,
        sources: this.value,
      }
    },
  }
</script>
